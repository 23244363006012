<template>
  <div class="container-library">
    <div v-if="Library !== undefined">
      <LibraryItem v-for="item in Library" v-bind:key="item.track.ID" v-bind:track="item.track" v-bind:spotifyTrack="item.spotifyTrack" class=""></LibraryItem>
    </div>
  </div>
</template>

<script>

import LibraryItem from "@/components/LibraryItem"

export default {
  name: "home",
  components: {
    LibraryItem
  },
  mounted() {
    this.$store.dispatch("getLibrary");
  },
  computed: {
    Library() {
      return this.$store.getters.library;
    }
  }
};
</script>

<style scoped lang="scss">
  .container-library{
    width:95%;
    margin-left:auto;
    margin-right: auto;
    
  }
</style>
